var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowLoading
    ? _c(
        "div",
        {
          staticClass: "mask",
          on: {
            touchmove: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "loading" }, [
            _c(
              "svg",
              { staticClass: "circular", attrs: { viewBox: "25 25 50 50" } },
              [
                _c("circle", {
                  staticClass: "circular-path",
                  attrs: {
                    cx: "50",
                    cy: "50",
                    r: "20",
                    fill: "none",
                    "stroke-width": "3",
                    "stroke-miterlimit": "10"
                  }
                })
              ]
            ),
            _c("p", { staticClass: "loading-text" }, [
              _vm._v(_vm._s(_vm.content))
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }