//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'loading',
  components: {},
  props: {},
  data: function data() {
    return {
      isShowLoading: false,
      content: '' };

  },
  watch: {},
  computed: {},
  methods: {},
  created: function created() {},
  mounted: function mounted() {} };